import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useNavigationType, useParams } from 'react-router-dom'

import { UilCheckCircle } from '@iconscout/react-unicons'
import { gaEvents } from '~/events'
import dayjs from 'dayjs'
import { Moment } from 'moment'

import { useAuth } from '~/_context/Auth'

import { useServiceUpdateProjectStatus } from '~/services/Lesson'
import { useTrackSchedule } from '~/services/Track'
import { useServiceUpdateTrail } from '~/services/TrailEdit'
import { useServiceEngageTrail } from '~/services/TrailSchedule'
import { useServiceDesengageTrail } from '~/services/TrailsVacate'

import { validateType } from '~/validations/setTypeModal'

import { defaultDataState } from './default.states'
import { IScheduleByWeekPageContextProps, IScheduleByWeekPageProviderProps } from './interfaces'

const ScheduleTrailPageContext = createContext<IScheduleByWeekPageContextProps>({
  currentDay: new Date(),
  changeCurrentDay: () => Object,
  selectedDay: new Date(),
  changeSelectedDay: () => Object,
  isLoading: false,
  data: defaultDataState,
  isRefetching: false,
  extensiveSelectedDay: '',
  weekArray: [{ formattedDay: '', className: '' }],
  itemsByDay: {
    Sunday: [],
  },
  daysOfWeekEN: [''],
  formattedMonthWithUppercase: '',
})

dayjs().locale('pt-br')

const ScheduleTrailProvider = ({ children }: IScheduleByWeekPageProviderProps) => {
  const { classId, trackId } = useParams()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const {
    data: dataTrackSchedule,
    isLoading: loadingGet,
    refetch,
    remove,
  } = useTrackSchedule(Number(classId), Number(trackId), !!classId && !!trackId)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [confirmModalDesengage, setConfirmModalDesengage] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [openModalEtapa, setOpenModalEtapa] = useState<boolean>(false)
  const [openModalRealize, setOpenModalRealize] = useState<boolean>(false)
  const [dateRealize, setDateRealize] = useState<string>('')
  const [idMoment, setIdMoment] = useState<number>(0)
  const [lastNuOrder, setLastNuOrder] = useState<number>(0)
  const [dates, setDates] = useState<Moment[]>([])
  const [changedIndexes, setChangedIndexes] = useState<number[]>([])
  const { user } = useAuth()
  const trackStageInfo = dataTrackSchedule?.trackStageInfo
  const title = dataTrackSchedule?.txTrackTitle
  const classInfo = dataTrackSchedule?.class
  const canDesengage = dataTrackSchedule?.moments.length > 0
  const projectType = dates?.every((date) => date?.project === 1)
  const isEditing = dates?.every((date) => date?.type === 'edit')

  useEffect(() => {
    remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = useCallback(() => {
    const hasDtSchedule = dates.some((date) => date?.dtSchedule)
    if (navigationType === 'PUSH' && hasDtSchedule) {
      navigate(-1)
    } else {
      navigate(`/class-details/${classId}`)
    }
  }, [classId, navigate, navigationType, dates])

  const { mutate: handleChangeStatus, isLoading: loadingRealize } = useServiceUpdateProjectStatus(
    'AUPL',
    Number(idMoment),
    Number(classId),
    {
      onSuccess: () => {
        let toastId: string | undefined

        if (loadingRequestSchedule) {
          toastId = toast.loading('Carregando...')
        }
        toast.success('Aula alterada com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        setOpenModalRealize(false)
        refetch()
      },
    },
  )

  useEffect(() => {
    if (dataTrackSchedule && dataTrackSchedule?.moments.length > 0) {
      const schedules = trackStageInfo?.map((stage) => {
        const matchingMoment = dataTrackSchedule?.moments?.find((moment) => moment.nuMomentOrder === stage.nuOrder)
        if (matchingMoment) {
          return {
            idTrackGroup: stage.idTrackGroup,
            nuOrder: stage.nuOrder,
            txTitle: stage.txTitle,
            type: 'edit',
            project: validateType(dataTrackSchedule.coStage),
            coStage: dataTrackSchedule.coStage,
            idMoment: matchingMoment.idMoment,
            coMomentStatus: matchingMoment.coMomentStatus,
            dtSchedule: matchingMoment.dtSchedule ?? null,
            professor: matchingMoment.professor,
          }
        }
      })
      setDates(schedules)
    } else {
      const schedules = trackStageInfo?.map((stage) => ({
        ...stage,
        type: 'schedule',
        dtSchedule: stage.dtSchedule ?? null,
        project: validateType(dataTrackSchedule.coStage),
        coStage: dataTrackSchedule.coStage,
      }))

      setDates(schedules)
    }
  }, [trackStageInfo, dataTrackSchedule?.moments, dataTrackSchedule])

  const {
    mutate: handleSchedule,
    error: errorRequestSchedule,
    isLoading: loadingRequestSchedule,
    data: dataScheduleRequest,
  } = useServiceEngageTrail()

  useEffect(() => {
    let toastId: string | undefined

    if (loadingRequestSchedule) {
      toastId = toast.loading('Carregando...')
    }

    if (!loadingRequestSchedule) {
      if (errorRequestSchedule) {
        toast.error(errorRequestSchedule.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorRequestSchedule && dataScheduleRequest) {
        toast.success('Trilha salva com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        navigate(`/class-details/${classId}`)
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [loadingRequestSchedule, errorRequestSchedule, dataScheduleRequest, navigate, classId])

  const {
    mutate: handleUpdate,
    error: errorUpdate,
    isLoading: loadingUpdate,
    data: dataUpdate,
  } = useServiceUpdateTrail()

  useEffect(() => {
    let toastId: string | undefined

    if (loadingUpdate) {
      toastId = toast.loading('Carregando...')
    }

    if (!loadingUpdate) {
      if (errorUpdate) {
        toast.error(errorUpdate.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorUpdate && dataUpdate) {
        toast.success('Trilha salva com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        handleBack()
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [
    loadingRequestSchedule,
    errorRequestSchedule,
    dataScheduleRequest,
    navigate,
    classId,
    loadingUpdate,
    errorUpdate,
    dataUpdate,
    handleBack,
  ])

  const {
    mutate: handleDesengage,
    error: errorDesengage,
    isLoading: loadingDesengage,
    data: dataDesengage,
  } = useServiceDesengageTrail()

  useEffect(() => {
    let toastId: string | undefined

    if (loadingDesengage) {
      toastId = toast.loading('Carregando...')
    }

    if (!loadingDesengage) {
      if (errorDesengage) {
        toast.error(errorDesengage.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorDesengage && dataDesengage) {
        toast.success('Trilha desocupada com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        navigate(`/class-details/${classId}`)
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [
    loadingRequestSchedule,
    errorRequestSchedule,
    dataScheduleRequest,
    loadingDesengage,
    errorDesengage,
    dataDesengage,
    navigate,
    classId,
  ])

  const handleConfirm = useCallback(() => {
    setConfirmModal(true)
  }, [setConfirmModal])

  const handleCloseConfirm = useCallback(() => {
    setConfirmModal(false)
  }, [setConfirmModal])

  const handleCloseSuccess = useCallback(() => {
    setOpenModalSucess(false)
  }, [])

  const actionSchedule = useCallback(() => {
    const dataRequest = {
      moments: dates?.map((item) => ({
        idTrackGroup: item.idTrackGroup,
        idMoment: item.idMoment,
        type: item.type,
        idUserProfessor: item?.professor?.idUser ?? item?.idUserProfessor,
        nuOrder: item.nuOrder,
        dtSchedule: item.dtSchedule,
      })),
    }

    const allItemsHaveEditOrScheduleType = dataRequest.moments?.every((item) => item.type === 'edit')

    if (allItemsHaveEditOrScheduleType) {
      handleUpdate({ body: dataRequest, classId: Number(classId), lessonId: Number(trackId) })
    } else {
      handleSchedule({ body: dataRequest, classId: Number(classId), lessonId: Number(trackId) })
    }
    // eslint-disable-next-line
  }, [dates])

  const disabledDate = useCallback(
    (date: dayjs.Dayjs, order: number) => {
      const numDates = dates?.length
      const today = dayjs()
      const dateList = Array.from({ length: numDates }, (_, i) => dayjs(dates[i].dtSchedule).startOf('day'))
      if (date.isBefore(today.startOf('day'))) {
        return true
      }
      for (let i = 1; i <= numDates; i++) {
        if (order === i) {
          if (date.isAfter(dateList[i - 1]) && date.isBefore(dateList[i])) {
            return false
          }
          if (date.isBefore(dateList[i - 2]) && i !== numDates && dateList[i - 2]) {
            return true
          }
          if (date.isAfter(dateList[i]) && i !== numDates) {
            return true
          }
          if (date.isBefore(dateList[i - 2]) && i === numDates) {
            return true
          }
          if (date.isSame(dateList[i]) && i === numDates) {
            return false
          }
        }
      }

      return false
    },
    [dates],
  )

  const validateMoment = useCallback(
    (moment: Moment) => {
      if (['edit'].includes(moment.type)) {
        const condition2 =
          moment?.professor &&
          moment.professor.idUser !== user?.id_user &&
          moment.professor.idUserProfessor !== user?.id_user
        const condition3 = ['FINA', 'AVPE', 'CANC'].includes(moment?.coMomentStatus)
        return condition2 || condition3
      }
    },
    [user?.id_user],
  )

  const datesFormatted = useMemo(() => {
    return dates?.map((date) => {
      const dtSchedule = date?.dtSchedule
      const disabled = validateMoment(date)
      return { dtSchedule, disabled }
    })
  }, [dates, validateMoment])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleDesengageTrail = useCallback(() => {
    setConfirmModalDesengage(true)
  }, [setConfirmModalDesengage])

  const handleCloseConfirmDesengage = useCallback(() => {
    setConfirmModalDesengage(false)
  }, [setConfirmModalDesengage])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleCloseSuccessDesengage = useCallback(() => {}, [])

  const handleDateChange = useCallback(
    ({ index, newDate }: { index: number; newDate: string }) => {
      const newDates = [...dates]
      const stageIndex = dates?.length || 0
      if (index === 0) {
        for (let i = 1; i <= stageIndex - 1; i++) {
          const nextIndex = index + i

          while (newDates.length <= nextIndex) {
            newDates.push({ dtSchedule: null })
          }

          if (!newDates[nextIndex].dtSchedule) {
            const currentDate = dayjs(newDate)
            const newDateIndex = currentDate.add(7 * i, 'days').format('YYYY-MM-DD')
            newDates[nextIndex].dtSchedule = newDateIndex
          }

          newDates[nextIndex].nuOrder = nextIndex + 1
        }
      }
      newDates[index].dtSchedule = newDate
      newDates[index].idUserProfessor = user?.id_user
      setDates(newDates)
      gaEvents.eventInputCalendar()
      setChangedIndexes((prevIndexes) => [...prevIndexes, index])
    },
    [dates, user?.id_user],
  )

  const validateSchedule = useCallback(() => {
    if (!dates || dates.length === 0) return false
    if (isEditing) return true

    if (projectType) {
      return dates.every((date) => !!date.dtSchedule)
    } else {
      return dates.some((date) => !!date.dtSchedule)
    }
  }, [dates, isEditing, projectType])

  const canSchedule = validateSchedule()

  const handleDateChangeSchedule = useCallback(
    (index: number, newDate: string) => {
      setDates((prevDates) => {
        const newDates = [...prevDates]
        const formattedDate = dayjs(newDate).format('YYYY-MM-DD')

        if (projectType) {
          if (newDates.length > 3) {
            gaEvents.eventInputCalendar()
            if (index === 0) {
              for (let i = 1; i <= 4; i++) {
                if (newDates[index + i] && !newDates[index + i].dtSchedule) {
                  const currentDate = dayjs(newDate)
                  const newDateIndex = currentDate.add(7 * i, 'days').format('YYYY-MM-DD')
                  newDates[index + i].dtSchedule = newDateIndex
                  newDates[index + i].idUserProfessor = user?.id_user
                }
              }
            }
          }
        } else {
          if (newDates[index] && !newDates[index].dtSchedule) {
            const currentDate = dayjs(newDate)
            newDates[index].dtSchedule = currentDate
            newDates[index].idUserProfessor = user?.id_user
          }
        }
        newDates[index].idUserProfessor = user?.id_user
        newDates[index].dtSchedule = formattedDate
        return newDates
      })
      setChangedIndexes((prevIndexes) => [...prevIndexes, index])
    },
    [projectType, user?.id_user],
  )

  const handleDesengageEtapa = useCallback(
    (nuOrder: number) => {
      setLastNuOrder(nuOrder)
      setOpenModalEtapa(true)
    },
    [setOpenModalEtapa],
  )

  const handleCloseDesengageEtapa = useCallback(() => {
    setOpenModalEtapa(false)
  }, [setOpenModalEtapa])

  const actionDesengage = useCallback(() => {
    if (projectType) {
      handleCloseConfirmDesengage()
      handleDesengage({ classId: Number(classId), lessonId: Number(trackId) })
    } else {
      setDates((prevDates) => {
        const loggedInProfessorId = user?.id_user
        const updatedDates = prevDates.map((date) => {
          if (
            (date?.professor?.idUser === loggedInProfessorId || date?.idUserProfessor === loggedInProfessorId) &&
            date?.coMomentStatus !== 'FINA' &&
            date?.coMomentStatus !== 'AUPE' &&
            date?.coMomentStatus !== 'AVPE' &&
            date?.coMomentStatus !== 'CANC'
          ) {
            const { dtSchedule, professor, idUserProfessor, coMomentStatus, ...rest } = date
            return rest
          }
          return date
        })

        return updatedDates
      })
      handleCloseConfirmDesengage()
    }
  }, [projectType, handleCloseConfirmDesengage, handleDesengage, classId, trackId, user?.id_user])

  const handleLeave = useCallback(() => {
    setDates((prevDates) => {
      const updatedDates = prevDates.map((date) => {
        if (date.nuOrder === lastNuOrder) {
          const { dtSchedule, professor, idUserProfessor, coMomentStatus, ...rest } = date
          return rest
        }
        return date
      })
      setLastNuOrder(0)
      handleCloseDesengageEtapa()
      return updatedDates
    })
  }, [setDates, lastNuOrder, handleCloseDesengageEtapa])

  const handleRealize = useCallback(
    (nuOrder: number, date: string) => {
      setLastNuOrder(nuOrder)
      setDateRealize(date)
      setOpenModalRealize(true)
    },
    [setOpenModalRealize],
  )

  const handleCloseRealize = useCallback(() => {
    setOpenModalRealize(false)
  }, [setOpenModalRealize])

  const handleConfirmRealize = useCallback(() => {
    setDates((prevDates) => {
      const matchedDate = prevDates.find((date) => date.nuOrder === lastNuOrder)
      if (matchedDate && matchedDate.idMoment) {
        setIdMoment(matchedDate.idMoment)
        handleChangeStatus()
      }
      return prevDates
    })
  }, [handleChangeStatus, lastNuOrder, setDates])

  const scheduleTrailPageProviderValues = useMemo(() => {
    return {
      dataTrackSchedule,
      dates,
      projectType,
      setDates,
      datesFormatted,
      validateMoment,
      disabledDate,
      loadingRequestSchedule,
      actionSchedule,
      confirmModal,
      handleCloseConfirm,
      openModalSucess,
      confirmModalDesengage,
      handleCloseConfirmDesengage,
      handleDesengageTrail,
      handleCloseSuccess,
      handleCloseSuccessDesengage,
      handleConfirm,
      actionDesengage,
      loadingDesengage,
      canDesengage,
      handleDateChange,
      handleDateChangeSchedule,
      classInfo,
      title,
      loadingUpdate,
      handleLeave,
      setOpenModalEtapa,
      openModalEtapa,
      handleDesengageEtapa,
      handleCloseDesengageEtapa,
      openModalRealize,
      handleRealize,
      handleCloseRealize,
      dateRealize,
      handleConfirmRealize,
      loadingRealize,
      loadingGet,
      canSchedule,
      handleBack,
      changedIndexes,
    }
  }, [
    dataTrackSchedule,
    dates,
    setDates,
    datesFormatted,
    projectType,
    validateMoment,
    disabledDate,
    loadingRequestSchedule,
    actionSchedule,
    confirmModal,
    handleCloseConfirm,
    openModalSucess,
    confirmModalDesengage,
    handleCloseConfirmDesengage,
    handleDesengageTrail,
    handleCloseSuccess,
    handleCloseSuccessDesengage,
    handleConfirm,
    actionDesengage,
    loadingDesengage,
    canDesengage,
    handleDateChange,
    handleDateChangeSchedule,
    classInfo,
    title,
    loadingUpdate,
    handleLeave,
    setOpenModalEtapa,
    openModalEtapa,
    handleDesengageEtapa,
    handleCloseDesengageEtapa,
    openModalRealize,
    handleRealize,
    handleCloseRealize,
    dateRealize,
    handleConfirmRealize,
    loadingRealize,
    loadingGet,
    canSchedule,
    handleBack,
    changedIndexes,
  ])

  return (
    <ScheduleTrailPageContext.Provider value={scheduleTrailPageProviderValues}>
      {children}
    </ScheduleTrailPageContext.Provider>
  )
}

const useScheduleTrailPageContext = () => useContext(ScheduleTrailPageContext)

export { ScheduleTrailProvider, useScheduleTrailPageContext }
